///
/// Dimension by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Image */

.image {
  border-radius: _size(border-radius);
  border: 0;
  display: inline-block;
  position: relative;

  &.country-flag {
    border: solid 1px;
    border-radius: 50%;
    margin: 2px;
    width: 1.5rem;
    padding: 1px;
    opacity: 0.5;

    &.hovered {
      opacity: 1;
    }
  }

  &:before {
    @include vendor('pointer-events', 'none');
    background-image: url('../../../images/overlay.png');
    background-color: _palette(bg-overlay);
    border-radius: _size(border-radius);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
  }

  img {
    border-radius: _size(border-radius);
    display: block;
  }

  &.left,
  &.right {
    max-width: 40%;

    img {
      width: 100%;
    }
  }

  &.left {
    float: left;
    padding: 0 1.5em 1em 0;
    top: 0.25em;
  }

  &.right {
    float: right;
    padding: 0 0 1em 1.5em;
    top: 0.25em;
  }

  &.fit {
    display: block;
    margin: 0 0 _size(element-margin) 0;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &.main {
    display: block;
    // margin: (_size(element-margin) * 1.25) 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    width: 50%;

    img {
      width: 100%;
    }
  }

  @include breakpoint(small) {
    &.main {
      margin: (_size(element-margin) * 1) 0;
    }
  }

  @include breakpoint(xsmall) {
    &.main {
      margin: (_size(element-margin) * 0.75) 0;
    }
  }
}
