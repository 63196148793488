.input-group {
  margin: 1rem;
}

.login-button {
  display: flex;
  justify-content: center;
  margin-left: 1rem;
}

.header-logo {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
}
